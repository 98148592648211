import { Button, Card, Grid, Heading, Input, TextArea } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import FlexContainer from '../../components/FlexContainer';
import { AlertContext } from '../../contexts/alert.context';
import useWindowDimensions from '../../hook/useWindowDimensions';
import { AppDispatch, useAppSelector } from '../../store';
import { PrizeType } from '../../store/dataTypes';
import {
  FETCH_PRIZE_CAMPAIGN_ByID,
  PATCH_PRIZES_CAMPAIGN,
} from '../../store/stock/PrizeCampaignReducer';
import { changeState, changeStateForm } from '../../utils/functions';
import QuantifierLabeled from '../../components/QuantifierLabeled/QuantifierLabeled';

export default function EditPrize() {
  const { addToast } = useContext(AlertContext);
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();

  const [search, setSearch] = useState('');

  const { width } = useWindowDimensions();
  const [form, setForm] = useState<PrizeType>(undefined);

  const prizeData: PrizeType = useAppSelector((state) => state.prizeCampaign.prizeData);
  const dispatch = useDispatch<AppDispatch>();
  const [hasEmptyField, setHasEmptyField] = useState<boolean>(false)
  const [winnerURLError, setWinnerURLError] = useState(false);
  const regexHTTP = /^(http:\/\/|https:\/\/).{1,}$/i;

  useEffect(() => {
    async function init() {
      await dispatch(
        FETCH_PRIZE_CAMPAIGN_ByID({
          campaignId: params.campaignId,
          id: params.id,
          game: state?.game,
        })
      );
    }
    init();
  }, []);

  useEffect(() => {
    setSearch(prizeData?.name);
    state?.game === 'bee_run' ? setForm({ ...prizeData, prize: prizeData?.prize, winner_redirect_link: prizeData?.winner_redirect_link === null ? { uri: '', title: '', options: [] } : prizeData?.winner_redirect_link, prize_description: prizeData?.prize_description === null ? '' : prizeData?.prize_description}) :
    setForm({ ...prizeData, prize: prizeData?.prize?.id });
  }, [prizeData]);

  async function handleSaveData() {
    if (state?.checkTimeWindow) {
      const matchingTimeWindows = state?.timeWindowData.filter(window => window.name === form.name);

      const latestTimeWindowCreated = state?.timeWindowData.reduce((latest, window) => {
        const windowCreatedDate = new Date(window.created);
        windowCreatedDate.setHours(windowCreatedDate.getHours() - 3);
        return latest > windowCreatedDate ? latest : windowCreatedDate;
      }, new Date(0));

      const filteredPrizesData = state?.prizesData.filter(prize => {
        const prizeCreatedDate = new Date(prize.created);
        return prize.name === form.name && prize.id !== form.id && prizeCreatedDate < latestTimeWindowCreated;
      });

      const totalQuantity = filteredPrizesData.reduce((sum, prize) => sum + prize.quantity, 0) + form.quantity;

      if (totalQuantity < matchingTimeWindows.length) {
        addToast({
          message: "Some of the prizes you've created do not match the quantity of time windows assigned for those prizes.",
          type: 'error',
        });
        return;
      }
    }
    if (form?.winner_redirect_link?.uri && !regexHTTP.test(form?.winner_redirect_link?.uri)) {
      addToast({
        message: 'Please insert URL with http:// or https://.',
        type: 'error',
      });
      setWinnerURLError(true);
      return;
    } else if (!form?.prize || !form?.quantity) {
      setHasEmptyField(true)
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
    } else {
      setHasEmptyField(false);
      setWinnerURLError(false);
      await dispatch(
        PATCH_PRIZES_CAMPAIGN({
          campaignId: params.campaignId,
          id: form.id,
          prizeData: {
            ...form,
            type: state?.game,
            winner_redirect_link: !form.winner_redirect_link
              ? { uri: '', title: '', options: [] }
              : form.winner_redirect_link,
          },
        })
      ).then(() => {
        navigate(-1);
      });
    }
  }

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Edit prize
          </Heading>
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="small" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'center',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
                alignItems: 'flex-end',
              }}
            >
              <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FlexContainer
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  alignItems="flex-end"
                  gap="1rem"
                >
                  <AutoComplete
                    onChange={(e) => {
                      changeState(setSearch, e.currentTarget.value);
                    }}
                    onClear={() => {
                      changeState(setSearch, '');
                      changeStateForm(setForm, form, 'prize', undefined);
                    }}
                    onClickResult={(prize) => {
                      setForm({
                        ...form,
                        prize: prize?.id,
                        prizeId: prize?.uuid
                      });
                    }}
                    value={search}
                    required
                    disabled={state?.checkTimeWindow}
                    size="large"
                    label="Prize"
                    hint="Prizes are registered in the Prizes page"
                    placeholder="Start typing to search"
                    hasError={!form?.prize && hasEmptyField}
                    errorText='Prize is required.'
                  />
                  {state?.game === 'spinning_wheel' ? (
                    <Input
                      defaultValue={form?.name}
                      value={form?.name}
                      required
                      disabled={state?.checkTimeWindow}
                      size="large"
                      label="Prize name"
                      hint="Prizes are registered in the Prizes page"
                      placeholder="Start typing to search"
                      onChange={(e) =>
                        changeStateForm(setForm, form, 'name', e.currentTarget.value)
                      }
                    />
                  ) : null}
                  <QuantifierLabeled
                    title='Quantity'
                    labelText="How many units of this prize are available"
                    defaultValue={form?.quantity}
                    value={form?.quantity}
                    min={1}
                    onChange={(e) => {
                      let v = parseInt(e.currentTarget.value, 10);
                      if (isNaN(v)) {
                        v = 1;
                      }
                      v = v <= 0 ? 1 : v;
                      changeStateForm(setForm, form, 'quantity', v);
                    }}
                    onClickPlusButton={() => {
                      changeStateForm(setForm, form, 'quantity', form?.quantity + 1);
                    }}
                    onClickMinusButton={() => {
                      changeStateForm(setForm, form, 'quantity', form?.quantity - 1);
                    }}
                  />
                  <Input
                    defaultValue={form?.winner_redirect_link?.uri}
                    value={form?.winner_redirect_link?.uri}
                    required
                    size="large"
                    label="Winner’s Redirect URL"
                    hint="Please insert URL with http:// or https://"
                    placeholder="Insert your text here"
                    hasError={winnerURLError}
                    disabled={state?.checkTimeWindow}
                    onChange={(e) => {
                      if (e.currentTarget.value && !regexHTTP.test(e.currentTarget.value)) {
                        setWinnerURLError(true);
                      } else {
                        setWinnerURLError(false);
                      }
                      setForm({
                        ...form,
                        winner_redirect_link: {
                          options: [],
                          uri: e.currentTarget.value,
                          title: form?.winner_redirect_link?.title,
                        },
                      });
                    }}
                  />
                  <Input
                    defaultValue={form?.winner_redirect_link?.title}
                    value={form?.winner_redirect_link?.title}
                    required
                    size="large"
                    label="Link text"
                    hint="The winner redirect link."
                    placeholder="Insert your text here"
                    disabled={state?.checkTimeWindow}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        winner_redirect_link: {
                          options: [],
                          uri: form?.winner_redirect_link?.uri,
                          title: e.currentTarget.value,
                        },
                      })
                    }
                  />
                  {state?.game === 'penalty_kick' || 'bee_run' && (
                      <div style={{ marginRight: '1rem', width: '38%' }}>
                        <TextArea
                          label="Description"
                          placeholder="Insert your text here"
                          value={form?.prize_description}
                          width={'100%'}
                          maxLength={150}
                          disabled={state?.checkTimeWindow}
                          characterCounter
                          style={{
                            height: '90px',
                            resize: 'none',
                          }}
                          onChange={(e) =>
                            changeStateForm(
                              setForm,
                              form,
                              'prize_description',
                              e.currentTarget.value
                            )
                          }
                        />
                      </div>
                    )}

                    {state?.game === 'spinning_wheel' && (
                      <>
                        <div style={{ marginRight: '1rem' }}>
                          <Input
                            width={250}
                            type="color"
                            value={form?.field_spin_prize_bg_color?.color || ''}
                            required
                            size="large"
                            label="Prize background color"
                            disabled={state?.checkTimeWindow}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                field_spin_prize_bg_color: {
                                  color: e.currentTarget.value,
                                  opacity: 1,
                                },
                              })
                            }
                          />
                        </div>
                        <Input
                          width={250}
                          type="color"
                          value={form?.field_spin_prize_text_color?.color || ''}
                          required
                          size="large"
                          label="Prize text color"
                          disabled={state?.checkTimeWindow}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              field_spin_prize_text_color: {
                                color: e.currentTarget.value,
                                opacity: 1,
                              },
                            })
                          }
                        />
                      </>
                    )}
                  {/* <div style={{ width: 200 }}>
                                        <Select.Root
                                            defaultValue={form?.type}
                                            value={form?.type}
                                            size="large"
                                            label="Prize type"
                                            hint='hint text'
                                            placeholder="value"
                                            onChange={(value) => changeStateForm(setForm, form, 'brand', value)}
                                        >
                                            <Select.Option value="1">Consolation Prize</Select.Option>
                                        </Select.Root>
                                    </div> */}
                </FlexContainer>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '20px',
                }}
              >
                <Button variant="secondary" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleSaveData()}>
                  Save
                </Button>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
