import { Card, IconButton, Tabs } from '@hexa-ui/components';
import { Edit2, Plus } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FlexContainer from '../../../components/FlexContainer';
import { AppDispatch, useAppSelector } from '../../../store';
import { PlayerAttemptType, PlayerType } from '../../../store/dataTypes/player.type';
import {
  FETCH_PLAYERS,
  FETCH_PLAYERS_ATTEMPTS,
  IMPORT_PLAYERS_CHECK,
  IMPORT_PLAYERS_ENTITY_STATUS,
} from '../../../store/stock/PlayersReducer';

import { SetStateAction } from 'react';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import ExportStatus from '../../../components/ExportStatus';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import StyledTabs from '../../../components/Tabs/Tabs';
import playersAttempsHeaders from '../../json/columnHeaders/playerAttempsHeaders.json';
import playersHeaders from '../../json/columnHeaders/playerHeaders.json';
import ExportModule from '../modules/ExportModal';

interface props {
  game;
}
const PlayersTab: React.FC<props> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [listType, setListType] = useState('base_player');
  const [attemptRequestData, setAttemptRequestData] = useState({
    campaign_id: id,
    search: '',
    page: 0,
    limit: 25,
    game: props.game
  });

  const [playersRequestData, setPlayersRequestData] = useState({
    campaign_id: id,
    search: '',
    page: 0,
    limit: 25,
  });

  const playersData: PlayerType[] = useAppSelector((state) => state.players.data);
  const totalPlayers: number = useAppSelector((state) => state.players.totalPlayers);
  const importPlayersCheck = useAppSelector((state) => state.players.check);
  const attemptsData: PlayerAttemptType[] = useAppSelector((state) => state.players.dataAttempt);
  const attemptTotalPages: number = useAppSelector((state) => state.players.totalAttempt);
  const campaignDetails = useAppSelector((state) => state.campaignDetails.data);

  const dispatch = useDispatch<AppDispatch>();

  const [hoverEdit, setHoverEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [playerFilteredData, setPlayerFilteredData] = useState<PlayerType[]>(playersData);
  const [playerAttempsFilteredData, setPlayerAttempsFilteredData] = useState<any[]>(attemptsData);
  const [searchPlayer, setSearchPlayer] = useState('');
  const [searchPlayerAttemps, setSearchPlayerAttemps] = useState('');

  useEffect(() => {
    if (id) {
      setLoading(true);
      const requestDataplayers = {
        campaign_id: id,
        ...playersRequestData,
      };
      setPlayersRequestData(requestDataplayers);
      dispatch(IMPORT_PLAYERS_CHECK({ id }));
      dispatch(FETCH_PLAYERS(requestDataplayers));
      dispatch(IMPORT_PLAYERS_ENTITY_STATUS({ id })).finally(() => setLoading(false));
      //const requestData = {
      //  campaign_id: id,
      //  ...attemptRequestData,
      //};
      //setAttemptRequestData(requestData);
      //dispatch(FETCH_PLAYERS_ATTEMPTS(requestData)).finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (listType === 'player_attempts_v2') {
        setLoading(true);
        const requestData = {
          campaign_id: id,
          ...attemptRequestData,
        };
        setAttemptRequestData(requestData);
        dispatch(FETCH_PLAYERS_ATTEMPTS(requestData)).finally(() => setLoading(false));
      }
      /*else {
        const requestData = {
          campaign_id: id,
          ...playersRequestData
        };
        setAttemptRequestData(requestData);
        dispatch(IMPORT_PLAYERS_CHECK({ id }));
        dispatch(FETCH_PLAYERS(requestData)).finally(() =>
          setPlayerAttempsFilteredData(playersData)
        );
        dispatch(IMPORT_PLAYERS_ENTITY_STATUS({ id })).finally(() => setLoading(false));
      }*/
    }
  }, [listType]);

  const getPlayers = () => {
    setLoading(true);
    dispatch(FETCH_PLAYERS(playersRequestData)).finally(() => setLoading(false));
  };

  const getPlayersAttemps = () => {
    setLoading(true);
    dispatch(FETCH_PLAYERS_ATTEMPTS(attemptRequestData)).finally(() => setLoading(false));
  };

  const handleAttemptPagination = (page, limit) => {
    attemptRequestData.page = page >= 1 ? page - 1 : page;
    attemptRequestData.limit = limit;
    setAttemptRequestData(attemptRequestData);
    getPlayersAttemps();
  };

  const handlePlayersPagination = (page, limit) => {
    playersRequestData.page = page >= 1 ? page - 1 : page;
    playersRequestData.limit = limit;
    setPlayersRequestData(playersRequestData);
    getPlayers();
  };

  const handleAttemptSearch = (value) => {
    attemptRequestData.page = 0;
    setAttemptRequestData(attemptRequestData);
    attemptRequestData.search = value;
    getPlayersAttemps();
  };

  const handlePlayerSearch = (value) => {
    playersRequestData.page = 0;
    playersRequestData.search = value;
    setPlayersRequestData(playersRequestData);
    getPlayers();
  };

  const debouncedSearchAttemps = useCallback(
    debounce((nextValue) => handleAttemptSearch(nextValue), 250),
    []
  );

  const debouncedSearchPlayer = useCallback(
    debounce((nextValue) => handlePlayerSearch(nextValue), 250),
    []
  );

  const changeFilter = (type: string, value: string) => {
    if (type === 'base_player') {
      setSearchPlayer(value);
      debouncedSearchPlayer(value);
    } else {
      setSearchPlayerAttemps(value);
      debouncedSearchAttemps(value);
    }
  };

  function insertActions(data: PlayerType[], setState?: SetStateAction<any>) {
    let aux: PlayerType[] = [];

    const actions = (code: PlayerType) => (
      <FlexContainer display="inline-flex" gap="1rem">
        <IconButton
          icon={Edit2}
          variant="inherit"
          onClick={() => handleEdit(code)}
          onMouseEnter={() => setHoverEdit(true)}
          onMouseLeave={() => setHoverEdit(false)}
        />
      </FlexContainer>
    );

    for (let i = 0; i < data.length; i++) {
      let item: PlayerType = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  }

  const handleEdit = (value: PlayerType) => {
    navigate('/bees-games/manage-assets/campaign-details/' + id + '/edit-player/' + value.id, {
      state: {
        name: value.name,
        poc: value.poc,
        quantity: value.quantity,
        campaign: value.campaign,
      },
    });
  };

  useEffect(() => {
    var data: PlayerType[] = insertActions(playersData);
    setPlayerFilteredData(data);
  }, [playersData]);

  useEffect(() => {
    //var data: any[] = insertActions(attemptsData);
    setPlayerAttempsFilteredData(attemptsData);
  }, [attemptsData]);

  const hasImportRunning = () => {
    if (listType === 'base_player') return !importPlayersCheck && !loading;
    return false;
  };

  const FlexBtn = styled('div', {
    display: 'flex',
    gap: '$1',
  });

  return (
    <>
      {hasImportRunning() && (
        <StyledAlert
          message={'You already have an import running. Wait for it to finish and try again!'}
          type={'warning'}
          css={{ width: '100%', marginBottom: 20 }}
        />
      )}
      <ExportStatus bundle={listType} campaignId={id} />
      <Card elevated={'medium'} border={'large'}>
        <StyledTabs defaultValue="base_player" value={listType}>
          <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
            <Tabs.Trigger value="base_player" onClick={() => setListType('base_player')}>
              Players
            </Tabs.Trigger>
            <Tabs.Trigger
              value="player_attempts_v2"
              onClick={() => setListType('player_attempts_v2')}
              style={{ display: props.game != 'bottle_crush' ? 'block' : 'none' }}
            >
              Players attempts
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content value="base_player" style={{ padding: '0px' }}>
            <FlexContainer
              width="100%"
              display={'inline-flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding="1rem"
              flexWrap="wrap"
              gap="1rem"
            >
              <div style={{ maxWidth: '496px', width: '496px' }}>
                <StyledFilter
                  placeholder="Start typing to filter results"
                  value={searchPlayer}
                  onChange={(e) => changeFilter('base_player', e.currentTarget.value)}
                  onClear={(e) => changeFilter('base_player', '')}
                />
              </div>
              <FlexBtn>
                <StyledButton
                  disabled={!importPlayersCheck}
                  variant="primary"
                  icon={Plus}
                  leading
                  onClick={() =>
                    navigate('/bees-games/manage-assets/campaign-details/' + id + '/add-player', {
                      state: {
                        campaignId: campaignDetails?.uuid,
                        campaignType: campaignDetails?.type?.target_id,
                      },
                    })
                  }
                >
                  Add Player
                </StyledButton>
                <StyledButton
                  disabled={!importPlayersCheck}
                  variant="primary"
                  icon={Plus}
                  leading
                  onClick={() =>
                    navigate(
                      '/bees-games/manage-assets/campaign-details/' + id + '/import-players',
                      {
                        state: {
                          idCampaign: id,
                        },
                      }
                    )
                  }
                >
                  Import players
                </StyledButton>
              </FlexBtn>
            </FlexContainer>
            <StyledTable
              loading={loading}
              columns={playersHeaders}
              data={Array.isArray(playerFilteredData) ? playerFilteredData : []}
              pagination={false}
            />
            <FlexContainer
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              padding={'0.5rem 1rem'}
              flexWrap="wrap"
            >
              <StyledPagination
                pageSize={playersRequestData.limit}
                total={totalPlayers}
                onChange={handlePlayersPagination}
              />
            </FlexContainer>
          </Tabs.Content>
          <Tabs.Content value="player_attempts_v2" style={{ padding: '0px' }}>
            <FlexContainer
              width="100%"
              display={'inline-flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding="1rem"
              flexWrap="wrap"
            >
              <div style={{ maxWidth: '496px', width: '496px' }}>
                <StyledFilter
                  placeholder="Start typing to filter results"
                  value={searchPlayerAttemps}
                  onChange={(e) => changeFilter('player_attempts_v2', e.currentTarget.value)}
                  onClear={(e) => changeFilter('player_attempts_v2', '')}
                />
              </div>
            </FlexContainer>
            <StyledTable
              columns={props.game === 'bee_run' ? playersAttempsHeaders.beeRunPlayerAttempts : playersAttempsHeaders.playerAttempts}
              loading={loading}
              data={attemptsData || []}
              pagination={false}
            />
            <FlexContainer
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              padding={'0.5rem 1rem'}
              flexWrap="wrap"
            >
              <StyledPagination
                pageSize={attemptRequestData.limit}
                total={attemptTotalPages}
                onChange={handleAttemptPagination}
              />
            </FlexContainer>
          </Tabs.Content>
        </StyledTabs>
      </Card>
      <ExportModule
        bundle={listType}
        campaignId={id}
        disabled={
          listType === 'base_player'
            ? !!!playerFilteredData?.length
            : !!!playerAttempsFilteredData?.length
        }
      />
    </>
  );
};

export default PlayersTab;
